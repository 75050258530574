<template>
  <div class="form">
    <!-- <h3>ID ИГРЫ</h3>
    <input class="input" v-model="gameId" /> -->
    <h3>НАЗВАНИЕ КОМАНДЫ</h3>
    <input class="input" v-model="teamName" maxlength="30"/>
    <input class="button" @click="join" type="button" v-bind:value="joinText" />
  </div>
</template>

<script>
export default {
  name: "JoinForm",
  data: () => {
    return {
      gameId: "",
      teamName: "",
    };
  },
  props: {
    gameIdPlaceholder: String,
    teamNamePlaceholder: String,
    joinText: String,
  },
  emits: ["join"],
  methods: {
    join() {
      this.$emit("join", {
        gameId: this.$data.gameId,
        teamName: this.$data.teamName,
      });
    },
  },
};
</script>

<style scoped>
.form {
  display: grid;
}

.input {
  background: linear-gradient(90rad, #ffe996, #bb8d40, #ffe996);
  border: none;
  color: #000;
  height: 2rem;
  margin-bottom: 16px;
  font-size: 18px;
  text-align: center;
}

.button {
  background: linear-gradient(90rad, #ffe996, #bb8d40, #ffe996);
  border: none;
  font-size: 1rem;
  padding: 8px;
  color: #00352b;
  font-weight: bold;
}

h3 {
  margin: 0 0 4px 0;
  text-align: center;
  color: #fff;
}
</style>

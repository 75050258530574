import { createStore } from "vuex";

export default createStore({
	state: {
		reconnectId: "",
		gameId: "",
		teamName: "",
		admin: false,
	},
	getters: {
		gameDetails: (state, getters) => {
			return { gameId: state.gameId, teamName: state.teamName, admin: state.admin };
		},
		reconnectId: (state, getters) => {
			return state.reconnectId;
		},
	},
	mutations: {
		setGameDetails(state, { gameId, teamName, admin }) {
			state.admin = admin;
			state.gameId = gameId;
			state.teamName = teamName;
		},
		setReconnectId(state, { gameId }){
			state.reconnectId = gameId;
		}
	},
	actions: {
		saveGameDetails({ commit, state }, { gameId, teamName, admin = false }) {
			commit("setGameDetails", { gameId, teamName, admin });
		},
		saveReconnectData({ commit, state }, { gameId }){
			commit("setReconnectId", { gameId });
		},
		clearGameDetails({ commit, state }) {
			commit("setGameDetails", { gameId: "", teamName: "", admin: false });
		},
	},
	modules: {},
});

import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import colyseus from "./plugins/colyseus";
import store from "./store";
// import VueIziToast from 'vue-izitoast';
// import Toasted from "vue-toasted";

createApp(App)
	.use(store)
	.use(router)
	.use(colyseus, { server: "wss://smartcity.ugscompany.ru/api" })
	.mount("#app");
// Vue.use(Toasted);

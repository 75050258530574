<template>
  <router-view />
</template>

<style>
@font-face {
  font-family: "ACADEBOL";
  src: url("/ACADEBOL.ttf") format("truetype");
}

@font-face {
  font-family: "ACADEITA";
  src: url("/ACADEITA.ttf") format("truetype");
}

@font-face {
  font-family: "ACADEROM";
  src: url("/ACADEROM.ttf") format("truetype");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
* {
  font-family: ACADEROM;
  font-weight: 500;
}

html {
  width: 100%;
  height: 100%;
}

body {
  background: #23302b;
  width: 100%;
  height: 100%;
  margin: 0;
  background: url('/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#app {
  /* display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center; */
  height: 100%;
  width: 100%;
  /* align-items: center; */
}
</style>

import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Gome",
		component: Home,
	},
	{
		path: "/game",
		name: "Game",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Game.vue"),
	},
	{
		path: "/admin",
		name: "Create",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Create.vue"),
	},
	{
		path: "/control",
		name: "Control",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Control.vue"),
	},
	{
		path: "/view",
		name: "View",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/View.vue"),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;

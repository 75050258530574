<template>
  <div class="home">
    <img src="/logo.png" />
    <JoinForm
      gameIdPlaceholder="ID игры..."
      teamNamePlaceholder="Название команды"
      joinText="ПРИСОЕДИНИТЬСЯ"
      v-on:join="joinToGame($event)"
    />
  </div>
</template>

<script>
import JoinForm from "@/components/JoinForm.vue";

export default {
  name: "Home",
  components: {
    JoinForm,
  },
  methods: {
    joinToGame({ gameId, teamName }) {
      console.log("joinToGame", { gameId, teamName });
      if (teamName) {
        this.$store.dispatch("saveGameDetails", { gameId, teamName });
        this.$router.push("/game");
      }
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  align-items: center;
}

img {
  width: 300px;
}
</style>